import companyLogo from "../assets/companyLogo.svg";
import technology from "../assets/technology.jpg";
import googleLogo from "../assets/googleLogo.svg";
import microsoftLogo from "../assets/microsoftLogo.svg";
import facebookLogo from "../assets/facebookLogo.svg";
import appointmentPatientAppIcon from "../assets/appointmentPatientAppIcon.png";
import appointmentClinicAppIcon from "../assets/appointmentClinicAppIcon.png";
import ethics from "../assets/ethics.png";
import tree from "../assets/tree.png";
import secure from "../assets/secure.png";
import aboutUs from "../assets/aboutUs.jpg";

const Colors = {
  primaryColor: "black",
  secondaryColor: "white",
  orangeType1: "#fe7819",
  grayType1: "rgba(255,255,255,0.3)",
  grayType2: "#1c1c1c",
  grayType3: "#4c4c4c",
};
const Images = {
  companyLogo,
  technology,
  googleLogo,
  microsoftLogo,
  facebookLogo,
  appointmentPatientAppIcon,
  appointmentClinicAppIcon,
  ethics,
  tree,
  secure,
  aboutUs,
};
const Constants = {
  slogan: "We Create Value For You",
  sloganSubText: "We create products which values you and you value them!",
  packCardButton: "Know More",
  pack: "Pack",
  ourProducts: "Our Products",
  ourPillars: "Our Pillars",
  aboutUsShort: `We are software technology company, provide various software solutions
  build in house. We build saas products for various domains such as medical, education,
  government, farming, e-commerce, and many more.`,
  aboutUsHeading: `We are bark of the tree!`,
  learnMoreAboutUs: `Learn more about us..`,
  aboutUs: "About Us",
  copyRight: "© minicubex",
  siteMap: "Site Map",
};
export { Colors, Images, Constants };
