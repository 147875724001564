import * as React from "react";
import styled from "styled-components";
import { Colors, Images } from "../utils/assets";
import ButtonType1 from "./ButtonType1";

const Container = styled.header`
  background-color: ${Colors.primaryColor};
  display: flex;
  flex-direction: row;
  padding: 0.5% 5% 0.5% 5%;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: ${Colors.grayType1};
`;
const BrandContainer = styled.div`
  display: flex;
`;
const OptionsContianer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;
const CompanyName = styled.img`
  width: 15rem;
  height: 5rem;
`;

const Header = () => {
  return (
    <Container>
      <BrandContainer>
        <CompanyName src={Images.companyLogo} alt={"logo"} />
      </BrandContainer>
      <OptionsContianer>
        <ButtonType1 label={"Go to Blog"} onClick={() => {}} />
      </OptionsContianer>
    </Container>
  );
};
export default Header;
