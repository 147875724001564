import * as React from "react";
import { useCallback } from "react";
import styled from "styled-components";
import { Colors, Constants } from "../utils/assets";
import ButtonType1 from "./ButtonType1";

const Container = styled.div`
  width: 18rem;
  height: 25rem;
  background-color: ${Colors.grayType2};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 20px;
  justify-content: center;
`;
const ProductNameContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;
const ProductPackName = styled.h4`
  color: ${Colors.secondaryColor};
  font-size: 1.5rem;
  padding: 0;
  margin: 0;
`;
const PackDetails = styled.p`
  color: ${Colors.secondaryColor};
  font-size: 1rem;
  text-align: center;
`;
const ProductContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0 0.5rem 0;
`;
const ProductIconContainer = styled.img`
  width: 3rem;
  height: 3rem;
`;
const ProdName = styled.p`
  color: ${Colors.secondaryColor};
  font-size: 1rem;
  padding-left: 1rem;
  flex: 1;
  display: flex;
`;
const ButtonContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0.5rem 0 0;
`;
const AllProductsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
    background-color: ${Colors.secondaryColor};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.orangeType1};
    height: 5px;
  }
`;
const ProductCard = (props) => {
  const { label, icon } = props;
  return (
    <ProductContainer>
      <ProductIconContainer src={icon} alt={label}></ProductIconContainer>
      <ProdName>{label}</ProdName>
    </ProductContainer>
  );
};
const ProjectInfoCard = (props) => {
  const { projectPackName, products, packDetails, onButtonClick } = props;

  const generateProducts = useCallback((list) => {
    return (
      Array.isArray(list) &&
      list.map((each) => {
        return (
          <ProductCard key={each.label} label={each.label} icon={each.icon} />
        );
      })
    );
  }, []);

  return (
    <Container>
      <ProductNameContainer>
        <ProductPackName>{projectPackName}</ProductPackName>
        <ProductPackName>{Constants.pack}</ProductPackName>
      </ProductNameContainer>
      <AllProductsContainer>{generateProducts(products)}</AllProductsContainer>
      <PackDetails>{packDetails}</PackDetails>
      <ButtonContainer>
        <ButtonType1 onClick={onButtonClick} label={Constants.packCardButton} />
      </ButtonContainer>
    </Container>
  );
};
export default ProjectInfoCard;
