import * as React from "react";
import { useCallback } from "react";
import styled from "styled-components";
import { Colors, Images, Constants } from "../utils/assets";

const Container = styled.div`
  display: flex;
  background-color: ${Colors.grayType2};
  padding: 0.5% 5% 0.5% 5%;
  flex-direction: column;
`;
const MainInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
const CompanyName = styled.img`
  width: 15rem;
  height: 5rem;
`;
const ListContainer = styled.div`
  display: flex;
  width: 20rem;
`;
const ListItemsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
`;
const ListHeading = styled.li`
  color: ${Colors.secondaryColor};
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0.3rem;
`;
const ListItems = styled.li`
  color: ${Colors.grayType3};
  font-size: 1.1rem;
  padding: 0.3rem;
  font-weight: bold;
`;
const LinkItem = styled.a`
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.5;
  }
  cursor: pointer;
`;
const BottomBar = styled.div`
  display: flex;
  flex-direction: row;
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: ${Colors.grayType1};
`;
const BottomBarText = styled.p`
  font-size: 1rem;
  color: ${Colors.grayType3};
  padding-right: 1rem;
`;
const BottomBarLinkText = styled.a`
  font-size: 1rem;
  color: ${Colors.grayType3};
  padding-right: 1rem;
  margin: 1rem;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.5;
  }
  cursor: pointer;
`;
const AllListMainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  padding: 3%;
`;
const footerItems = [
  {
    heading: "Company",
    items: ["About Us", "Jobs", "Blog", "Press"],
  },
  {
    heading: "Help",
    items: ["Contact Us", "Our policies", "Term & Conditions", "Feedback"],
  },
];
const Footer = () => {
  const generateListItems = useCallback((list, onItemClick) => {
    return (
      Array.isArray(list) &&
      list.map((each, indx) => {
        return (
          <ListItems onClick={onItemClick} key={each + indx}>
            <LinkItem>{each}</LinkItem>
          </ListItems>
        );
      })
    );
  }, []);

  const generateLists = useCallback(
    (list, onItemClick) => {
      return (
        Array.isArray(list) &&
        list.map((each, indx) => {
          return (
            <ListContainer key={each.heading + indx}>
              <ListItemsContainer>
                <ListHeading>{each.heading}</ListHeading>
                {generateListItems(each.items, onItemClick)}
              </ListItemsContainer>
            </ListContainer>
          );
        })
      );
    },
    [generateListItems]
  );
  const onItemClick = () => {};
  return (
    <Container>
      <MainInfoContainer>
        <CompanyName src={Images.companyLogo} alt={"logo"} />
        <AllListMainContainer>
          {generateLists(footerItems, onItemClick)}
        </AllListMainContainer>
      </MainInfoContainer>
      <BottomBar>
        <BottomBarText>
          {Constants.copyRight} {new Date().getFullYear()}
        </BottomBarText>
        <BottomBarLinkText>{Constants.siteMap}</BottomBarLinkText>
      </BottomBar>
    </Container>
  );
};
export default Footer;
