import * as React from "react";
import styled from "styled-components";
import { Colors } from "../utils/assets";

const Container = styled.div`
  display: flex;
  width: 20rem;
  flex-direction: column;
  margin: 0.5rem;
`;
const BeliefHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const BeliefHeadingIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.5rem;
  padding-left: 0;
`;
const BeliefHeadingText = styled.h4`
  color: ${Colors.secondaryColor};
  font-size: 1.5rem;
  padding: 0;
  margin: 0;
`;
const Content = styled.p`
  color: ${Colors.secondaryColor};
`;

const CompanyBeliefsCard = (props) => {
  const { heading, icon, content } = props;
  return (
    <Container>
      <BeliefHeadingContainer>
        <BeliefHeadingIcon src={icon} />
        <BeliefHeadingText>{heading}</BeliefHeadingText>
      </BeliefHeadingContainer>
      <Content>{content}</Content>
    </Container>
  );
};

export default CompanyBeliefsCard;
