import * as React from "react";
import { useCallback } from "react";
import styled from "styled-components";
import { Colors, Images } from "../utils/assets";

const Container = styled.section`
  display: flex;
  flex-direction: row;
  background-color: ${Colors.primaryColor};
  padding: 0.5% 5% 0.5% 5%;
  flex-direction: column;
`;
const CompanyListHeading = styled.p`
  font-size: 0.8rem;
  color: ${Colors.secondaryColor};
`;
const ListContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap:wrap ;
`;
const Logo = styled.img`
  width: 10rem;
  height: "auto";
  margin: 1rem;
`;

const logoList = [
  { id: "logo_google", img: Images.googleLogo },
  { id: "logo_microsoft", img: Images.microsoftLogo },
  { id: "logo_facebook", img: Images.facebookLogo },
];

const CompanyList = () => {
  const generateLogoList = useCallback(() => {
    return logoList.map((each, indx) => {
      return <Logo key={each.id} src={each.img} alt={each.id} />;
    });
  }, []);

  return (
    <Container>
      <CompanyListHeading>Companies we inspired by</CompanyListHeading>
      <ListContainer>{generateLogoList(logoList)}</ListContainer>
    </Container>
  );
};

export default CompanyList;
