import * as React from "react";
import { useCallback } from "react";
import styled from "styled-components";
import { Colors, Images, Constants } from "../utils/assets";
import CompanyBeliefsCard from "./CompanyBeliefsCard";

const Container = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.primaryColor};
  align-items: center;
  padding-bottom: 5rem;
`;
const BeliefsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
const Heading = styled.h2`
  color: ${Colors.secondaryColor};
  font-size: 1.5rem;

  padding-bottom:5px ;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: ${Colors.grayType1};
`;
const CompanyBeliefsList = () => {
  const generateCards = useCallback((list) => {
    return (
      Array.isArray(list) &&
      list.map((each, indx) => {
        return (
          <CompanyBeliefsCard
            key={each.heading + indx}
            heading={each.heading}
            icon={each.icon}
            content={each.content}
          />
        );
      })
    );
  }, []);

  return (
    <Container>
      <Heading>{Constants.ourPillars}</Heading>
      <BeliefsContainer>
        {generateCards([
          {
            heading: "Ethics",
            icon: Images.ethics,
            content:
              "We believe in Strong Ethics in all the aspects of our org, it is the major pillar of our organization & its growth. ",
          },
          {
            heading: "Value Creation",
            icon: Images.tree,
            content:
              "Our primary motive is to create a value to our customers, we want's to be a part of their growth. ",
          },
          {
            heading: "Secure",
            icon: Images.secure,
            content:
              "We do our best to protect your data, your data is always yours but securing is our responsibility.",
          },
        ])}
      </BeliefsContainer>
    </Container>
  );
};
export default CompanyBeliefsList;
