import * as React from "react";
import { useCallback } from "react";
import styled from "styled-components";
import { Colors, Constants, Images } from "../utils/assets";
import ButtonType1 from "./ButtonType1";

const Container = styled.section`
  min-height: 100vh;
  background-color: ${Colors.primaryColor};
  display: flex;
  flex-wrap: wrap;
  padding: 0.5% 5% 0.5% 5%;
`;
const LeftContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const RightContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CompanySlogan = styled.h5`
  color: ${Colors.secondaryColor};
  font-size: 5rem;
  padding: 0px;
  margin: 0px;
  @media only screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;
const SloganSubText = styled.h4`
  color: ${Colors.secondaryColor};
  font-size: 2rem;
  padding: 0px;
  margin: 0px;
  @media only screen and (max-width: 480px) {
    font-size: 1rem;
  }
`;
const ButtonContainer = styled.div`
  padding: 1rem 0 0 0;
`;
const TechImage = styled.img`
  width: 100%;
`;
const MainBanner = () => {
  const onExploreClick = useCallback(() => {}, []);
  return (
    <Container>
      <LeftContainer>
        <CompanySlogan>{Constants.slogan}</CompanySlogan>
        <SloganSubText>{Constants.sloganSubText}</SloganSubText>
        <ButtonContainer>
          <ButtonType1 onClick={onExploreClick} label={"Explore"} />
        </ButtonContainer>
      </LeftContainer>
      <RightContainer>
        <TechImage src={Images.technology} alt={"technology"} />
      </RightContainer>
    </Container>
  );
};

export default MainBanner;
