import styled from "styled-components";
import Header from "../components/Header";
import MainBanner from "../components/MainBanner";
import CompanyList from "../components/CompanyList";
import ProjectList from "../components/ProjectList";
import CompanyBeliefsList from "../components/CompanyBeliefsList";
import AboutUs from "../components/AboutUs";
import Footer from "../components/Footer";

const Container = styled.div``;
const Dashboard = () => {
  return (
    <Container>
      <Header />
      <MainBanner />
      <CompanyList />
      <ProjectList />
      <AboutUs />
      <CompanyBeliefsList />
      <Footer />
    </Container>
  );
};

export default Dashboard;
