import * as React from "react";
import styled from "styled-components";
import { Colors } from "../utils/assets";

const MainContainer = styled.div`
  display: flex;
  justify-content: "center";
  align-items: "center";
`;
const Container = styled.button`
  background-color: ${Colors.orangeType1};
  padding: 0.8rem 1.5rem 0.8rem 1.5rem;
  color: ${Colors.secondaryColor};
  border-radius: 5rem;
  font-weight: bold;
  border-width: 0;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 1;
  }
`;

const ButtonType1 = (props) => {
  const { label, onClick } = props;
  return (
    <MainContainer>
      <Container onClick={onClick}>{label}</Container>
    </MainContainer>
  );
};
export default ButtonType1;
