import * as React from "react";
import { useCallback } from "react";
import styled from "styled-components";
import { Colors, Constants, Images } from "../utils/assets";
import ProjectInfoCard from "./ProjectInfoCard";

const Container = styled.section`
  background-color: ${Colors.primaryColor};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5%;
`;
const Heading = styled.h2`
  color: ${Colors.secondaryColor};
  font-size: 1.5rem;
  
  padding-bottom:5px ;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: ${Colors.grayType1};
`;
const ProjectListContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width:100% ;
`;

const projectInfoList = [
  {
    projectPackName: "Clinic Easy Appointment",
    packDetails:
      "Pack includes all the above applications along with Cloud and Maintanance",
    products: [
      {
        label: "Easy Clinic Mobile App (Doctor, Admin, Receptionist Roles)",
        icon: Images.appointmentClinicAppIcon,
      },
      {
        label: "Easy Patient Booking Mobile App",
        icon: Images.appointmentPatientAppIcon,
      },
    ],
  },
  {
    projectPackName: "Coming Soon 1",
    packDetails:
      "Pack includes all the above applications along with Cloud and Maintanance",
    products: [],
  },
  {
    projectPackName: "Coming Soon 2",
    packDetails:
      "Pack includes all the above applications along with Cloud and Maintanance",
    products: [],
  },
];
const ProjectList = () => {
  const generateProjectInfoCards = useCallback((list) => {
    return (
      Array.isArray(list) &&
      list.map((each, indx) => {
        const { projectPackName, packDetails, products } = each;
        return (
          <ProjectInfoCard
            key={"generateProjectInfoCards" + indx}
            projectPackName={projectPackName}
            packDetails={packDetails}
            products={products}
          />
        );
      })
    );
  }, []);

  return (
    <Container>
      <Heading>{Constants.ourProducts}</Heading>
      <ProjectListContainer>
        {generateProjectInfoCards(projectInfoList)}
      </ProjectListContainer>
    </Container>
  );
};

export default ProjectList;
