import * as React from "react";
import { useCallback } from "react";
import styled from "styled-components";
import { Colors, Constants, Images } from "../utils/assets";
import ButtonType1 from "./ButtonType1";

const Container = styled.section`
  background-color: ${Colors.primaryColor};
  display: flex;
  flex-direction: column;
  padding: 5%;
  justify-content:center ;
  align-items:center ;
`;
const SubContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
`;
const LeftContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const RightContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TechImage = styled.img`
  width: 50%;
  border-radius: 5%;
`;
const Heading = styled.h5`
  color: ${Colors.secondaryColor};
  font-size: 3rem;
  padding: 0px;
  margin: 0px;
  @media only screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;
const AboutUsText = styled.p`
  color: ${Colors.secondaryColor};
  font-size: 1rem;
  @media only screen and (max-width: 480px) {
    font-size: 1rem;
  }
`;
const ButtonContainer = styled.div`
  padding: 1rem 0 0 0;
`;
const MainHeading = styled.h2`
  color: ${Colors.secondaryColor};
  font-size: 1.5rem;
  text-align: center;
  padding-bottom: 2rem;

  padding-bottom:5px ;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: ${Colors.grayType1};
`;
const AboutUs = () => {
  const onExploreClick = useCallback(() => {}, []);
  return (
    <Container>
      <MainHeading>{Constants.aboutUs}</MainHeading>
      <SubContainer>
        <LeftContainer>
          <Heading>{Constants.aboutUsHeading}</Heading>
          <AboutUsText>{Constants.aboutUsShort}</AboutUsText>
          <ButtonContainer>
            <ButtonType1
              onClick={onExploreClick}
              label={Constants.learnMoreAboutUs}
            />
          </ButtonContainer>
        </LeftContainer>
        <RightContainer>
          <TechImage src={Images.aboutUs} alt={"aboutus"} />
        </RightContainer>
      </SubContainer>
    </Container>
  );
};
export default AboutUs;
